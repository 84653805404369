'use client';
import React, { Fragment, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';

import { useLocale, useTranslations } from 'next-intl';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@shadcn/components/ui/form';
import { FormInput } from '@shadcn/components/ui/input';

import { Button } from '../Button';
import { useCart } from '../../_providers/Cart';
import { extractId } from '../../../shared/extract-id';
import Plus from '../../_icons/Plus';
import dayjs from 'dayjs';
import ChevronCircleTick from '../../_icons/Chevron-circle-tick';

export const DiscountCodeForm: React.FC = () => {
	const t = useTranslations('Checkout');
	const {
		cart,
		setDiscountCodeText,
		discountCodeError,
		isDiscountCodeLoading,
		clearDiscountCode,
		isCartSyncInProgress,
		cartTotalDiscountCodeReduction,
	} = useCart();

	const formSchema = z.object({
		code: z.string(),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			code: cart.discountCodeText || '',
		},
	});

	useEffect(() => {
		if (discountCodeError) {
			form.setError('code', {
				message: discountCodeError,
			});
		}
	}, [discountCodeError]);

	const {
		formState: { isSubmitting, isDirty, isValid },
	} = form;
	async function onSubmit(values: z.infer<typeof formSchema>) {
		setDiscountCodeText((values.code || '').trim());
	}

	const isDiscountCodeAppliable = cart.cartItems?.some(cartItem => cartItem.discountCodeApplied);
	const hasDiscountCode = cart.discountCode && cart.discountCodeText;
	// code was entered but doesn't apply to any cart item
	const discountCodeNotAppliable =
		hasDiscountCode && !isDiscountCodeAppliable && !isDiscountCodeLoading;
	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="max-w-md">
				<FormField
					control={form.control}
					name="code"
					render={({ field }) => (
						<FormItem className="mb-1">
							<FormControl>
								<div className="relative flex w-full max-w-sm items-center space-x-2">
									<FormInput
										placeholder={t('discount-code')}
										type="text"
										className={`pr-20 ${hasDiscountCode && isDiscountCodeAppliable ? 'border-success' : ''}`}
										{...field}
									/>
									{hasDiscountCode ? (
										<ChevronCircleTick className="absolute right-2 rounded-lg bg-success " />
									) : (
										<Button
											type="submit"
											size="small"
											intent="primary"
											variant="ghost"
											className="absolute right-2"
											loading={isDiscountCodeLoading}
											disabled={isDiscountCodeLoading || isCartSyncInProgress}
										>
											{t('add')}
											<Plus></Plus>
										</Button>
									)}
								</div>
							</FormControl>

							{discountCodeNotAppliable ? (
								<p className="text-sm font-medium text-muted-error">
									{t('discount-code-not-appliable')}
								</p>
							) : (
								<FormMessage></FormMessage>
							)}

							{hasDiscountCode && (
								<div className="flex items-baseline justify-between">
									<Button
										size="small"
										variant="ghost"
										intent="primary"
										className="pt-0 underline"
										disabled={isCartSyncInProgress}
										onClick={() => {
											clearDiscountCode();
											form.setValue('code', '');
										}}
									>
										{t('remove-code')}
									</Button>
									{cartTotalDiscountCodeReduction.raw > 0 && (
										<span className="font-bold text-success">
											-{cartTotalDiscountCodeReduction.formatted}
										</span>
									)}
								</div>
							)}
						</FormItem>
					)}
				/>
			</form>
		</Form>
	);
};
2270;
